//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import PageHeader from '@/components/tools/PageHeaderCustom.vue';
import SearchBox from './components/SearchBox.vue';
import {
  PhotoCellRenderer,
  NameCellRenderer,
  DesCellRenderer,
  CreatedCellRenderer,
  ActionsCellRenderer,
  SubDomainCellRenderer,
  SecurityCellRenderer,
  DictionaryCellRenderer,
} from './grid';
// import { IframeCodeCellRender } from './page/grid';
import { PROJECT_STATUS } from '@/enum';
import * as pubsub from '@/pubsub';
import { urlByAnyData } from '@/utils/util';
import PublicSettingModal from './page/codes/modal/PublicCodesSetting.vue';
import DuplicateModal from './components/DuplicateModal';
import ChangeStatusModal from './components/ChangeStatusModal';
import CardView from './card-view/Index.vue';

const PAGE_SIZE = 24;

export default {
  components: {
    PageHeader,
    SearchBox,
    PublicSettingModal,
    DuplicateModal,
    ChangeStatusModal,
    CardView,
    ArchiveModal: () => import('./components/ArchiveModal.vue'),
    ImportModal: () => import('./components/ImportModal.vue'),
    PhotoCellRenderer,
    NameCellRenderer,
    DesCellRenderer,
    CreatedCellRenderer,
    ActionsCellRenderer,
    SubDomainCellRenderer,
    SecurityCellRenderer,
    DictionaryCellRenderer
  },
  data() {
    return {
      projects: [],
      queryParam: {
        title: '',
        sub_domain: '',
        mode: this.$ls.get('project-mode', 'card')
      },
      loading: false,
      context: { componentParent: this },
      pagination: { current: 1, pageSize: PAGE_SIZE, total: 0 },
      selectedProjects: [],
      interactedProject: {},
      gridApi: null,
      projectArchiveVisible: false,
      projectImportVisible: false,
      publicSettingModalVisible: false,
      duplicateModalVisible: false,
      changeStatusModalVisible: false
    };
  },
  created() {
    const { title, sub_domain, size, page, mode, order } = this.$route.query;
    this.queryParam = {
      title: title || '',
      sub_domain: sub_domain || '',
      mode: mode || this.$ls.get('project-mode', 'card'),
      ...(order && { order })
    };
    this.pagination.pageSize = +size || PAGE_SIZE;
    this.pagination.current = +page || 1;
    this.getData();
  },
  computed: {
    ...mapGetters('user', ['userInfo']),
    columnDefs() {
      return [
        {
          headerName: 'project.grid.Photo',
          field: 'photo',
          width: 150,
          lockPosition: true,
          cellRenderer: 'PhotoCellRenderer'
        },
        {
          headerName: 'project.grid.Name',
          field: 'name',
          sortable: true,
          unSortIcon: true,
          lockPosition: true,
          cellRenderer: 'NameCellRenderer'
        },
        {
          headerName: 'project.grid.URL',
          field: 'sub_domain',
          sortable: true,
          unSortIcon: true,
          lockPosition: true,
          cellRenderer: 'SubDomainCellRenderer'
        },
        {
          headerName: 'project.grid.Description',
          field: 'description',
          lockPosition: true,
          cellRenderer: 'DesCellRenderer'
        },
        {
          headerName: 'project.grid.Created',
          field: 'created',
          width: 120,
          sortable: true,
          unSortIcon: true,
          lockPosition: true,
          cellRenderer: 'CreatedCellRenderer'
        },
        {
          headerName: 'project.grid.Dictionary',
          field: 'dictionary',
          width: 120,
          lockPosition: true,
          cellRenderer: 'DictionaryCellRenderer'
        },
        {
          headerName: 'project.grid.PublishSetting',
          field: 'PublishSetting',
          width: 120,
          lockPosition: true,
          cellRenderer: 'SecurityCellRenderer'
        },
        {
          headerName: 'project.grid.Actions',
          field: 'actions',
          width: 135,
          lockPosition: true,
          pinned: 'right',
          cellRenderer: 'ActionsCellRenderer'
        }
      ];
    },
    filter() {
      const { title, sub_domain, order } = this.queryParam;
      let where = {
        ...(title && { name: { ilike: `%${title}%` } }),
        ...(sub_domain && { sub_domain: { ilike: `%${sub_domain}%` } }),
        pinned: null,
        status: 'ACTIVE'
      };
      const skip = (this.pagination.current - 1) * this.pagination.pageSize;
      const filter = {
        limit: this.pagination.pageSize,
        skip,
        where,
        order: order || 'created DESC'
      };
      return filter;
    },
    isSelectedProject() {
      return this.selectedProjects.length ? true : false;
    },
    companyId() {
      return this.userInfo.company_id;
    }
  },
  methods: {
    ...mapActions('project', ['loadProjects', 'addProject', 'updateProjectById']),
    async getData() {
      try {
        this.loading = true;
        const companyId = this.userInfo.company_id;
        const [pinnedProjects, projects, count] = await Promise.all([
          this.$s.api.project.find(companyId, {
            filter: {
              where: {
                pinned: {
                  neq: null
                },
                status: 'ACTIVE'
              },
              order: 'pinned DESC'
            }
          }),
          this.$s.api.project.find(companyId, { filter: this.filter }),
          this.$s.api.project.count(companyId, this.filter.where)
        ]);
        this.projects = [...pinnedProjects, ...projects];
        this.loadProjects({ payload: this.projects })
        this.pagination = { ...this.pagination, total: count?.count };
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    handleGridReady(params) {
      this.gridApi = params.api;
    },
    getRowClass(params) {
      return params.data.pinned ? 'cell-pinned' : '';
    },
    goToProjectCompose() {
      this.$router.push('/projects/compose');
    },
    openProjectImport() {
      this.projectImportVisible = true;
    },
    openArchiveModal() {
      this.projectArchiveVisible = true;
    },
    handleSearch() {
      this.$router.replace({ query: this.queryParam });
    },
    onChangeView() {
      this.queryParam.mode = this.queryParam.mode === 'card' ? 'grid' : 'card';
      this.$ls.set('project-mode', this.queryParam.mode);
      this.handleSearch();
    },
    handleReset() {
      this.queryParam = { title: '', sub_domain: '', mode: this.$route.query.mode || '' };
      this.handleSearch();
    },
    changeTitle(value) {
      this.queryParam.title = value;
    },
    changeSubDomain(value) {
      this.queryParam.sub_domain = value;
    },
    handleChangePage({ page, pageSize }) {
      this.pagination = { ...this.pagination, current: page, pageSize };
      this.$router.replace({ query: { ...this.queryParam, page, size: pageSize } });
    },
    handleSelectProject(event, type = 'grid-view') {
      if (type === 'grid-view') {
        let nodes = event.api.getSelectedNodes();
        this.selectedProjects = nodes.map(node => node.data);
      } else {
        this.selectedProjects = event.data;
      }
    },
    onOpenChangeStatusModelFromChild(project) {
      this.changeStatusModalVisible = true;
      this.interactedProject = project;
    },
    onOpenDuplicateProjectModalFromChild(project) {
      this.duplicateModalVisible = true;
      this.interactedProject = project;
    },
    async handleDuplicateProject(projectId, payload) {
      try {
        this.loading = true;
        await this.$s.api.project.duplicateProject(projectId, {
          is_duplicate_pages: payload.isDuplicatePages,
          is_duplicate_codes: payload.isDuplicateCodes
        });
        this.$message.success(`${this.$t('Duplicate project successfully')}`);
        this.getData();
      } catch (error) {
        console.log(error);
        this.$message.error(`${this.$t('Duplicate project failed')}`);
      } finally {
        this.loading = false;
        this.duplicateModalVisible = false;
      }
    },
    async handlePinToTopFromChild(data) {
      try {
        const changeStatus = data.pinned ? false : true;
        await this.$s.api.project.pinningProject(data.id, changeStatus);
        this.$message.success(`${this.$t('Successful')}`);
        this.getData();
      } catch (error) {
        console.log(error);
        this.$message.error(`${this.$t('Failed')}`);
      }
    },
    async handleProjectArchiving({ resolve, reject }) {
      try {
        const ids = this.selectedProjects.map(proj => proj.id);
        const data = {
          ids,
          status: PROJECT_STATUS.ARCHIVE
        };
        await this.$s.api.project.archiveProjects(data);
        this.$message.success(`${this.$t('Archive successfully')}`);
        this.selectedProjects = [];
        this.getData();
        this.projectArchiveVisible = false;
        resolve(1);
      } catch (error) {
        this.$notification.error({ message: `${this.$t('Archive failed')}: ${error.message}` });
        reject(error);
      }
    },
    async handleProjectImporting({ url, resolve, reject }) {
      try {
        await this.$s.api.project.importProjectByUrl(url);
        const subscribePayload = {
          collectionName: 'IMPORT_PROJECT',
          method: 'POST'
        };
        this.projectImportVisible = false;
        resolve(1);
        this.$notification.open({
          message: this.$t('Importing... Please wait for a little bit!'),
          duration: 0,
          icon: <a-icon type="smile" style="color: #108ee9" />
        });
        pubsub.subscribe(this.sockets, subscribePayload, response => {
          this.$notification.destroy();
          if (response.status === 'FAILED') {
            this.$notification.error({
              message: this.$t('Importing the project failed.'),
              description: response?.message || ''
            });
          } else {
            // Add to store
            this.addProject({ payload: response.data });
            this.getData();
            this.$notification.success({
              message: this.$t('Importing the project succeed.')
            });
          }
          pubsub.unSubscribeAll(this.sockets);
        });
      } catch (error) {
        reject(error);
        this.$notification.error({
          message: this.$t('Importing the project failed.'),
          description: error?.message || ''
        });
      }
    },
    async handleProjectExportFromChild(project) {
      try {
        await this.$s.api.project.exportProjectById(project.id, {
          includes: ['pages', 'codes']
        });
        const subscribePayload = {
          collectionName: 'EXPORT_PROJECT',
          method: 'GET',
          modelId: project.id
        };
        this.$notification.open({
          message: this.$t('Exporting... Please wait for a little bit!'),
          duration: 0,
          icon: <a-icon type="smile" style="color: #108ee9" />
        });
        pubsub.subscribe(this.sockets, subscribePayload, response => {
          this.$notification.destroy();
          if (response.status === 'FAILED') {
            this.$notification.error({
              message: this.$t('Exporting the project failed.'),
              description: response?.message || ''
            });
          } else {
            const url = urlByAnyData({ data: response?.data, contentType: 'application/json' });
            const a = document.createElement('a');
            a.href = url;
            a.download = project?.name || 'file-name';
            a.click();
            a.remove();
            this.$notification.success({
              message: this.$t('Exporting the project succeed.')
            });
          }
          pubsub.unSubscribeAll(this.sockets);
        });
      } catch (error) {
        console.log(error);
        this.$notification.error({
          message: this.$t(error?.message || 'error')
        });
      }
    },
    async handleChangeStatusFromChild({ type = 'single', projectIds, id, payload }) {
      const successNotifyMessage = {
        true: this.$t('This project is public.'),
        false: this.$t('This project is private.')
      };
      const errorNotifyMessage = {
        true: this.$t('This project public failed.'),
        false: this.$t('This project private failed.')
      };
      try {
        this.loading = true;
        const input = {
          is_public: payload.isPublic,
          includes: payload.includes
        };
        if (type === 'single') {
          projectIds = [id];
        }
        await Promise.all(
          projectIds.map(id => {
            return this.$s.api.project.editProjectById(this.companyId, id, input);
          })
        );
        this.projects.forEach(project => {
          if (projectIds.includes(project.id)) {
            project.is_public = payload.isPublic;
          }
        });

        this.$notification.success({ message: successNotifyMessage[payload.isPublic] });
      } catch (error) {
        this.$notification.error({ message: errorNotifyMessage[payload.isPublic] });
        throw new Error(error.message);
      } finally {
        this.loading = false;
        this.changeStatusModalVisible = false;
      }
    },
    async updatePublicSetting(payload) {
      this.loading = true;
      this.publicSettingModalVisible = false;
      let includes = ['page'];
      const projectIds = this.selectedProjects.map(item => item.id);
      this.handleChangeStatusFromChild({
        type: 'multiple',
        projectIds,
        payload: {
          isPublic: payload.ispublic,
          includes
        }
      });
      this.selectedProjects = [];
      this.gridApi.deselectAll();
      this.loading = false;
    }
  }
};
